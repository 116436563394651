<template>
  <div>
    <h1 class="title is-1">Test3</h1>
    <b-button @click="test">
      Show msg
    </b-button>
  </div>
</template>

<script>

import store from '@/store'

export default {
  name: 'Test1',
  components: {},
  data () {
    return {}
  },
  methods: {
    async test () {
      const response = {
        error_message: 'Hiba...',
        reference: 'Ref....',
      }
      await store.dispatch('showMessage', {
        type: 'warning',
        actionText: 'Hibakód másolása',
        indefinite: true,
        cancelText: 'OK',
        onAction: () => {
          navigator.clipboard.writeText(response.reference).then(async function () {
            await store.dispatch('showMessage', {
              type: 'success',
              msg: 'Hibakód sikeresen másolva a vágólapra',
            })
          }, async function () {
            await store.dispatch('showMessage', {
              type: 'warning',
              actionText: 'OK',
              onAction: () => {},
              msg: `Sikertelen másolás, hivatkozzon az alábbi hibakódra, ref: ${response.reference}`,
            })
          })
        },
        msg: `${response.error_message} | ref: ${response.reference}`,
      })
    },
  },
}
</script>

<style scoped>

</style>
